.text {
    width: 200px; height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.0em;
}

.spinCircle {
    -webkit-animation-name: spinner;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 0.4s;
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 0.4s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

/* WebKit and Opera browsers */ @-webkit-keyframes spinner {
                                    from
                                    {
                                        -webkit-transform: rotateY(0deg);
                                    }
                                    to {
                                        -webkit-transform: rotateY(-360deg);
                                    }
                                } /* all other browsers */
@keyframes spinner {
    from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to
    {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);

    }
}

.flip {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}


/* arrow */
.arrow {
    border: solid red;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 8px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}