a {
    color: #d85a54 !important;
    cursor: pointer;
}
a:hover{
    color: #803215 !important;
}
.header{
    height: 110px;
    margin-top: 20px;
}
.top-nav-warp {
    margin-top: 10px;
}
.back-arrow-warp{
    text-decoration: 'none',
}
input[type="button"], .top-nav-link{
    font-family: "Microsoft YaHei","微软雅黑","黑体","宋体";
    background-color: transparent;
    color: white;
    border: none;
    line-height: 25px;
}
input[type="button"]:hover , .top-nav-link:hover{
    color: #d85a54;
    text-decoration: none;
}
input[type="button"]:focus {
    border: none;
    outline-color: transparent;
}
.btn-history {
    margin-left: 15px;
}
.top-nav-warp > div {
    float: right;
}
.top-nav-link{
    padding-left: 6px;
}
.logo{
    max-width: 130px;
    padding-top: 10px;
}
.logo-wrap > div {
    display: inline;
}
.year-print {
    margin-top: 36px;
}
img.logo-heading{
    width: 60%;
    margin-left: 20px;
}
.drawing-content{
    height: 100%;
    margin-top: 4%;
    font-size: 1.5em;
    margin-bottom: 45px;
}
.year-print{
    text-align: right;
}
.drawing-frame {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 5% 5% 10%!important;
    position: relative;
}
.best-draw-img {
    position: absolute;
    right: -14%;
    top: 10%;
    width: 30%;
    max-width: 150px;
}
.drawing-wrap{
    text-align: right;
    /* transform: translate(0%,4%); */
}
.drawing-img {
    width: 75%;
}
.drawed-img{
    width: 62%;
}
hr.card-divider {
    border-top: 1px solid white;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-result{
    margin-top: 4%;
    margin-bottom: 10%;
    overflow-y: scroll;
    position: relative;
}
.draw-again-btn{
    position: absolute;
    bottom: 21px;
    width: 80% !important;
    left: 10%;
}
.card-description, .card-description-eng, .history-title{
    text-align: center;
}
.card-description, .history-title{
    font-family: "Microsoft YaHei","微软雅黑","黑体","宋体";
    color: #803215;
    line-height: 30px;
    font-size: 24px;
}
.card-description-eng{
    font-family: "Times New Roman";
    font-style: italic;
    font-size: 17px;
    padding: 0 5%;
}
.button-large{
    color: #d85a54;
    font-weight: bold;
    text-decoration: none;
    padding: 13px;
    border-radius: 37px;
    text-align: center;
    margin: 25px auto;
    background-color: white;
    width: 100%;
    display: flex!important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
a.button-large:hover {
    color: white !important;
    text-decoration: none;
    background-color: #d85a54;
}
a.button-large:last-child {
    margin-bottom: 0;
}

/* Result Card Page */
.result-card{
    font-family: "Microsoft YaHei","微软雅黑","黑体","宋体";
    color: #803215;
    line-height: 36px;
    font-size: 24px;
    text-align: left;
}
.drawing-result .drawing-frame {
    text-align: center;
}
.best-draw-card{
    display: none;
}
.result-title-img{
    width: 55%;
    margin-bottom: 46px;
}

/*History Page*/
.history-title{
    margin-top: 40px;
}
.category-title{
    text-align: center;
    margin-top: 25px;
    padding: 5px 10px;
    margin: 25px 10px 5px;
    color: white;
    background-color: #d08341;
}
.history-card{
    padding: 10px 15px 10px;;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
    margin: 10px;
    color: #803215;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.3);
}
.delete-btn{
    display: inline;
    padding-left: 3px;
}
/* @media only screen and (min-width: 600px) and (max-width: 991px) { 
    
} */
@media (max-width: 991px) {  
    body{
        background-position-x: 32%;
    }
    p {
        margin-bottom: 4%;
    }
    .drawing-frame {
        padding: 10% 7% 19% !important;
    }
    .button-large{
        margin: 10% auto;
        padding: 25px;
    }
    hr.card-divider {
        margin-top: 7%;
        margin-bottom: 5%;
    }
    .year-print {
        line-height: 16px;
    }
    .drawingImg {
        width: 90%;
    }
    .header {
        height: auto;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 767px) {
    body{
        background-position-x: 44%;
    }
    .logo{
        width: 27%;
        padding-top: 0;
    }
    img.logo-heading {
        width: 67%;
        margin-left: 5px;
    }
    .drawing-frame{
        padding-left: 7% !important;
        padding-right: 7% !important;
        padding-bottom: 35% !important;
    }
    .top-nav-warp {
        margin-top: 0;
    }
    .year-print {
        margin-top: 0;
    }
    .year-pring-img{
        display: none;
    }
    .best-draw-img{
        display: none;
    }
    .best-draw-card{
        display: block;
        width: 10%;
        position: absolute;
        top: 0px;
        right: 25px;
        transform: rotate(9deg);
    }
}

.buttonPage {
    /*border: 5px solid #ffffff;*/
    background-color: #ff0000;
    color: #ffffff;
}

.buttonPage:hover {
    /*border: 5px solid #ff0000;*/
    background-color: #ffffff;
    color: #ff0000;
}